import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  ImageDataLike,
} from 'gatsby-plugin-image';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import LightBox from '../components/Lightbox';

interface ImageFile {
  file: ImageDataLike;
}

interface Props {
  title: string;
  heroImage: ImageFile;
  images: ImageFile[];
}

const GalleryContiner = styled.div`
  width: 50%;

  @media only screen and (max-width: 700px) {
    width: 100%;
  }
`;

const MainImageContainer = styled.div`
  width: 100%;

  cursor: pointer;

  transition: transform 0.2s;

  z-index: 10;

  &:hover {
    transform: scale(101%);
  }
`;

const PreviewImagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ImgContainerPreview = styled.div`
  max-width: 25%;

  cursor: pointer;

  position: relative;

  &:hover:after {
    position: absolute;
    z-index: -1;
    content: '';
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 3px solid ${({ theme }) => theme.color.greyLight};
    top: 0;
    left: 0;
  }
`;

export const ProductImageGallery = ({ heroImage, images, title }: Props) => {
  const [showLightbox, setShowLightbox] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(
    null
  );

  const [selectedImage, setSelectedImage] = useState(getImage(heroImage.file));

  const handlePreviewImage = (image: IGatsbyImageData) => {
    setSelectedImage(image);
  };

  const handlePreviewImageExit = () => {
    setSelectedImage(getImage(heroImage.file));
  };

  const lightBoxImages = useMemo(
    () => [
      <GatsbyImage
        image={getImage(heroImage.file)}
        alt={`${title}-main`}
        style={{ width: '100%', height: '100%' }}
        objectFit="scale-down"
        loading="eager"
      />,
      ...(images?.map((img, index) => (
        <GatsbyImage
          key={`${title}-${index}`}
          image={getImage(img.file)}
          alt={`${title}-${index}`}
          style={{ width: '100%', height: '100%' }}
          objectFit="scale-down"
          loading="eager"
        />
      )) || []),
    ],
    [images, heroImage.file]
  );

  const handleOpen = (i: number) => {
    setShowLightbox(true);
    setSelectedImageIndex(i);
  };

  const handleClose = () => {
    setShowLightbox(false);
    setSelectedImageIndex(null);
  };

  const handlePrevRequest = (i: number, length: number) => () => {
    setSelectedImageIndex((i - 1 + length) % length);
  };

  const handleNextRequest = (i: number, length: number) => () => {
    setSelectedImageIndex((i + 1) % length);
  };

  return (
    <>
      <GalleryContiner>
        <MainImageContainer onClick={() => handleOpen(0)}>
          <GatsbyImage image={selectedImage} alt={title} />
        </MainImageContainer>

        <PreviewImagesContainer>
          {images?.map((img, index) => (
            <ImgContainerPreview
              key={index}
              onMouseEnter={() => handlePreviewImage(getImage(img.file))}
              onMouseLeave={() => handlePreviewImageExit()}
              onClick={() => handleOpen(index + 1)}
            >
              <GatsbyImage image={getImage(img.file)} alt={title} />
            </ImgContainerPreview>
          ))}
        </PreviewImagesContainer>
      </GalleryContiner>

      {showLightbox && selectedImageIndex !== null && (
        <LightBox
          images={lightBoxImages}
          handleClose={handleClose}
          handleNextRequest={handleNextRequest}
          handlePrevRequest={handlePrevRequest}
          selectedImage={selectedImageIndex}
        />
      )}
    </>
  );
};
