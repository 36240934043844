import React from 'react';
import { Helmet } from 'react-helmet';
import { seed } from '../content/seed';
import { theme } from '../design/theme';

interface Props {
  description: string;
  keywords: string[];
  title: string;
}

const ProductMetaTags = ({ title, keywords, description }: Props) => {
  const titleFull = `${title} | ${seed.meta.title}`;
  return (
    <Helmet
      htmlAttributes={{
        lang: 'sk',
      }}
      title={titleFull}
      defer={false}
    >
      <meta charSet="utf-8" />
      <title>{titleFull}</title>
      <meta name="description" content={description} />
      <meta name="theme-color" content={theme.color.blue} />
      <meta name="msapplication-navbutton-color" content={theme.color.blue} />
      <meta
        name="apple-mobile-web-app-status-bar-style"
        content={theme.color.blue}
      />

      <meta name="keywords" content={`${keywords}, ${seed.meta.keywords}`} />
      <meta property="og:title" content={titleFull} />
      <meta property="og:description" content={description} />
      <meta property="og:locale" content="sk_SK" />
      <meta property="og:site_name" content={seed.title.title} />
      <meta name="robots" content="index, follow" />
      <meta name="googlebot" content="index,follow,snippet,archive" />
      <meta name="author" content={seed.footer.author} />
    </Helmet>
  );
};

export default ProductMetaTags;
