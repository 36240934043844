import { graphql } from 'gatsby';
import { ImageDataLike } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';
import { Back } from '../components/Back';
import SiteTemplate from '../components/pagesTemplate/SiteTemplate';
import ProductMetaTags from '../components/ProductMetaTags';
import { H3, TextSmall } from '../design/SharedComponents';

import { ContactForm } from '../components/ContactForm';
import { ProductImageGallery } from '../components/ProductImageGallery';
import { Markdown } from '../components/Markdown';

interface ImageFile {
  file: ImageDataLike;
}

interface Product {
  id: string;
  description: string;
  categories: Array<{ name: string }>;
  slug: string;
  title: string;
  heroImage: ImageFile;
  images: ImageFile[];
  type: string;
}

export const query = graphql`
  query Products($slug: String!) {
    strapiProduct(slug: { eq: $slug }) {
      slug
      description
      title
      id
      type
      heroImage {
        file {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              aspectRatio: 1.33
            )
          }
        }
      }
      images {
        file {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              aspectRatio: 1.33
            )
          }
        }
      }
      categories {
        name
      }
    }
  }
`;

interface Props {
  data: {
    strapiProduct: Product;
  };
}

const TextContainer = styled.div`
  width: 50%;
  padding: 5px 15px 15px 15px;

  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  box-shadow: 0px 0px 15px #0000002b;

  @media only screen and (max-width: 700px) {
    width: 100%;
  }
`;

const ProductContainer = styled.div`
  margin: 10px 0;
  display: flex;
  flex-direction: row;

  width: 90%;
  max-width: 1200px;

  gap: 10px;

  @media only screen and (max-width: 800px) {
    width: 95%;
  }

  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

const CategoryContainer = styled.div`
  width: 100%;
  margin-top: auto;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
`;

const Category = styled(TextSmall)`
  width: fit-content;
  padding: 1px 2px;
  border-radius: 3px;
  border: 1px solid #464d4e80;
  color: #464d4e;
`;

const PageContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;
`;

const ContactContainer = styled.div`
  box-sizing: border-box;
  padding: 10px;

  margin-top: auto;
  margin-bottom: 10px;

  display: flex;
  flex-direction: row;

  width: 90%;
  max-width: 1000px;

  @media only screen and (max-width: 800px) {
    width: 95%;
  }

  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

const Product = ({ data }: Props) => {
  const product = data.strapiProduct;

  return (
    <SiteTemplate>
      <ProductMetaTags
        description={product.description}
        title={product.title}
        keywords={product.categories?.map((c) => c.name) || []}
      />

      <Back
        to={`/${product.type}`}
        text={`${
          product.type === 'produkty' ? 'Naša ponuka' : 'Naše služby'
        } / ${product.title}`}
      />

      <PageContainer>
        <ProductContainer>
          <ProductImageGallery
            images={product.images}
            heroImage={product.heroImage}
            title={product.title}
          />

          <TextContainer>
            <H3 style={{ marginBlockStart: '0.7em' }}>{product.title}</H3>
            <div>
              <Markdown>{product.description}</Markdown>
            </div>
            {product.categories && (
              <CategoryContainer>
                {product.categories.map((c) => (
                  <Category key={c.name}>{c.name}</Category>
                ))}
              </CategoryContainer>
            )}
          </TextContainer>
        </ProductContainer>

        <ContactContainer>
          <ContactForm product={product.title} />
        </ContactContainer>
      </PageContainer>
    </SiteTemplate>
  );
};

export default Product;
