import React, { ChangeEvent, useState } from 'react';
import { send } from '@emailjs/browser';
import { ButtonSecondary, H3 } from '../design/SharedComponents';
import styled from 'styled-components';

const SERVICE_ID = 'service_unikovo';
const TEMPLATE_ID = 'template_product';
const USER_ID = 'user_NFOuBfY93uYcakm8s7gAb';

type Props = {
  product: string;
};

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  margin-bottom: 10px;
`;

const EmailInput = styled.input`
  width: 100%;
  min-height: 40px;

  box-sizing: border-box;

  font-family: Montserrat, sans-serif;
  font-size: 18px;

  border: 1px solid ${(props) => props.theme.color.grey};

  &::placeholder {
    color: #c7c7c7;
  }
`;

const TextInput = styled.textarea`
  width: 100%;
  min-height: 100px;

  box-sizing: border-box;
  padding: 5px 2px;

  font-family: Montserrat, sans-serif;
  font-size: 18px;

  border: 1px solid ${(props) => props.theme.color.grey};

  &::placeholder {
    color: #c7c7c7;
  }
`;

const Label = styled.label`
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  line-height: 1.4;

  margin: 0;

  color: ${(props) => props.theme.color.black};
`;

export const ContactForm = ({ product }: Props) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // TODO: form validation - email, not empty

    var params = {
      product_name: product,
      product_link: window.location.href,
      email: email || 'No email provided',
      message: message || 'No message provided',
    };

    send(SERVICE_ID, TEMPLATE_ID, params, USER_ID).then(
      (response) => {
        console.log({ response });
      },
      (error) => {
        console.log({ error });
      }
    );
  };

  const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const onMessageChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  };

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <H3 style={{ textAlign: 'center' }}>
        V prípade záujmu nás neváhajte kontaktovať
      </H3>
      <form
        id="form"
        onSubmit={onSubmit}
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          width: '100%',
          maxWidth: '768px',
        }}
      >
        <InputContainer>
          <Label htmlFor="email">Vaša emailová adresa</Label>
          <EmailInput
            type="text"
            name="email"
            id="email"
            // placeholder="meno@email.sk"
            value={email}
            onChange={onEmailChange}
          />
        </InputContainer>
        <InputContainer>
          <Label htmlFor="message">Vaša správa</Label>
          <TextInput
            name="message"
            id="message"
            // placeholder="Mal by som záujem..."
            value={message}
            onChange={onMessageChange}
          />
        </InputContainer>

        <ButtonSecondary
          type="submit"
          id="button"
          style={{ padding: '5px 1em' }}
        >
          Odoslať správu
        </ButtonSecondary>
      </form>
    </div>
  );
};
